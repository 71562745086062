@import '../components/variables';

.contact-sec {
    border: 3px double $theme-color;
    border-radius: 15px !important;
}

.account-top-bar {
    border-bottom: 1px solid $theme-color;
}

.spacing-col-home {
    display: none;
}

@media screen and (min-width: 980px) {
    .col-home:last-child {
        border-left: 3px double $theme-color;
        border-bottom-left-radius: 0%;
    }
}

@media screen and (max-width: 980px) {
    .spacing-col-home {
        display: block;
        margin-top: 20px;
        float: left;
    }
}

@media screen and (max-height: 850px) {
    .img-exemple1 {
        height: 250px;
    }

    .home-account-sec {
        padding-top: 20px;
    }
}

@media screen and (max-height: 700px) {
    .img-exemple1 {
        height: 200px;
    }

    .home-account-sec {
        padding-top: 20px;
    }
}
